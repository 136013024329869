import {Grid, Stack} from "@mui/material";
import {FunctionField, ImageField, Show, SimpleShowLayout} from "react-admin";
import {Container, Typography} from "@material-ui/core";
import {ROUTE_URLS} from "../../api/constants";
import {useWriterStyles} from "./writerStyles";

export const ShowWriter = (props) => {
    const optionRenderer = choice => <Typography
        variant='h3'>{choice.surname} {choice.name} {choice.middleName}</Typography>;
    const classes = useWriterStyles()

    return <Container>
        <Show {...props}
              actions={false}
        >
            <SimpleShowLayout>
                <FunctionField label='' source='o' render={record => {
                    record.imageUrl = `${ROUTE_URLS.IMAGE}/${record.imageName}`
                }}/>
                <Grid container spacing={5}>
                    <Grid item
                          xs={12}
                          sm={12}
                          md={7}
                          lg={6}
                          xl={6}
                          style={{textAlign: "center"}}
                    >
                        <Stack className={classes.showContainer} spacing={3}>
                            <FunctionField render={record => optionRenderer(record)}/>
                            <ImageField source='imageUrl' label="Текущее изображение" fullWidth/>
                        </Stack>
                    </Grid>
                    <Grid item
                          xs={12}
                          sm={12}
                          md={5}
                          lg={6}
                          xl={6}
                    >
                        <FunctionField render={record => {
                            return <div className={classes.description}
                                        dangerouslySetInnerHTML={{__html: record.biography}}/>
                        }}/>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    </Container>
}

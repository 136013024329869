import {makeStyles} from "@material-ui/core/styles";

const iconSize = '100%'
export const useAppBarStyles = makeStyles(() => ({
    image: {
        width: '40px',
        height: '40px',
        marginRight: '-10px'
    },
    icon: {
        minHeight: iconSize,
        minWidth: iconSize,
        color: "white"
    },
    caption: {
        flexGrow: '1',
        marginLeft: '15px',
        textDecoration: 'none',
        color: 'white',
        textTransform: 'uppercase',
        fontFamily: 'Neucha',
        fontWeight: 400,
        fontSize: '20pt'
    },
    loginButton: {
        color: '#929da6',

        '&&:hover': {
            color: 'rgba(245,246,247,1)',
        }
    }
}))
import * as React from "react";
import {TablePagination} from "@mui/material";

export const ResearchToolkitPagination = props => <TablePagination
    component="div"
    count={props.total}
    page={props.page - 1}
    rowsPerPage={props.perPage}
    onPageChange={(e, a) => props.setPage(a + 1)}
    onRowsPerPageChange={(e) => props.setPerPage(e.target.value)}
    rowsPerPageOptions={[5, 10, 20, 50, 100]}
    labelRowsPerPage={'Строк на странице'}
/>;
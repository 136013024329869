import {FunctionField, ImageField, ImageInput, SimpleForm, TextInput} from "react-admin";
import {Grid} from "@mui/material";
import TextEditor from "../../components/TextEditor";
import {ROUTE_URLS} from "../../api/constants";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    textEditor: {
        minHeight: '500px'
    }
})

export const FormWriter = ({isNew, ...props}) => {
    const classes = useStyles()
    return <SimpleForm {...props}>
        {!isNew && <FunctionField label='' source='o' render={record => {
            record.imageUrl = `${ROUTE_URLS.IMAGE}/${record.imageName}`
        }}/>}
        <Grid container spacing={5}>
            <Grid item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
            >
                <TextInput source='surname'
                           label='Фамилия'
                           fullWidth
                />
                <TextInput source='name'
                           label='Имя'
                           fullWidth
                />
                <TextInput source='middleName'
                           label='Отчество'
                           fullWidth
                />
                <ImageInput source='image' label='Изображение писателя'>
                    <ImageField source="src" label="Новое изображение"/>
                </ImageInput>
                {!isNew && <ImageField source='imageUrl' label="Текущее изображение"/>}
            </Grid>
            <Grid item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  className={classes.textEditor}
            >
                <TextEditor source='biography'/>
            </Grid>
        </Grid>
    </SimpleForm>
}
import {Box} from "@material-ui/core";
import {Grid, Typography} from "@mui/material";
import {
    FileField,
    FileInput,
    FormDataConsumer,
    FunctionField,
    ImageField,
    ImageInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import {ROUTE_URLS} from "../../api/constants";
import TextEditor from "../../components/TextEditor";
import {showStyles} from "./bookStyles";
import MuiAudioPlayer from "mui-audio-player-plus"

export const FormBook = ({...props}) => {
    const classes = showStyles()

    const optionRenderer = choice => `${choice.surname} ${choice.name}  ${choice.middleName}`;
    return (
        <SimpleForm {...props}>
            <FunctionField label='' source='o' render={record => {
                record.imageUrl = `${ROUTE_URLS.IMAGE}/${record.imageName}`
                record.audioUrl = `${ROUTE_URLS.AUDIO}/${record.audioName}`
            }}/>
            <Grid container spacing={5}>
                <Grid item
                      xs={12}
                      sm={12}
                      md={7}
                      lg={6}
                      xl={6}
                >
                    <TextInput source='name'
                               label='Название произведения'
                               fullWidth
                    />
                    <ReferenceInput source="classesId"
                                    reference={ROUTE_URLS.CLASSES}
                                    sort={{field: 'name', order: 'ASC'}}
                                    label='Класс'
                    >
                        <SelectInput optionText="name"
                                     label='Класс'
                                     fullWidth
                        />
                    </ReferenceInput>

                    <ReferenceInput source="writerId"
                                    reference={ROUTE_URLS.WRITER}
                                    sort={{field: 'name', order: 'ASC'}}
                                    label='Писатель'
                    >
                        <SelectInput optionText={optionRenderer}
                                     label='Писатель'
                                     fullWidth
                        />
                    </ReferenceInput>
                    <Box>
                        <ImageInput source='image' label='Изображение писателя'>
                            <ImageField source="src" label="Новое изображение"/>
                        </ImageInput>
                        <Typography>Текущее изображение</Typography>
                        <ImageField source='imageUrl' label="Текущее изображение"/>
                    </Box>
                    <Box>
                        <FileInput source='audio' label='Аудиозапись'>
                            <FileField source="src" title="title"/>
                        </FileInput>
                        <FormDataConsumer>
                            {({formData}) => {
                                return !!formData.audio?.src && <Box>
                                    <Typography>Новая аудиозапись</Typography>
                                    <MuiAudioPlayer
                                        display="timeline"
                                        containerWidth={'100%'}
                                        inline
                                        src={formData.audioUrl}/>
                                </Box>
                            }}
                        </FormDataConsumer>
                        <Typography>Текущая аудиозапись</Typography>
                        <FunctionField render={record => {
                            return <MuiAudioPlayer
                                display="timeline"
                                containerWidth={'100%'}
                                inline
                                src={record.audioUrl}/>
                        }}/>
                    </Box>


                </Grid>
                <Grid item
                      xs={12}
                      sm={12}
                      md={5}
                      lg={6}
                      xl={6}
                      className={classes.textEditor}
                >
                    <TextEditor source='description'/>
                </Grid>
            </Grid>
        </SimpleForm>
    )
}
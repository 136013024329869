import {BooleanInput, maxLength, PasswordInput, required, SelectInput, SimpleForm, TextInput,} from 'react-admin';
import {USER_ROLE, USER_ROLE_CHOICES} from "../../api/constants";
import {Box, IconButton, InputAdornment} from "@material-ui/core";
import {useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {validateEditUserForm, validateNewUserForm} from "../../validation/validateUserDetails";
import {useFormStyles} from "./userFormStyles";

const UserDetailsForm = ({isNew, permissions, record, isCurrentUser, ...restProps}) => {
    const classes = useFormStyles();
    const [hasConfirm, setHasConfirm] = useState(true)

    return (
        <SimpleForm {...restProps}
                    redirect="list"
                    validate={hasConfirm
                        ? isNew
                            ? validateNewUserForm
                            : validateEditUserForm
                        : ''
                    }
        >
            <Box id="columnsContainer" className={classes.columns}>
                <Box className={classes.content}>
                    <Box className={classes.container}>
                        <TextInput source="name"
                                   options={{autoComplete: 'none'}}
                                   label="Фамилия Имя Отчество"
                                   disabled={!permissions.isAdmin}
                                   validate={[maxLength(256), required()]}
                                   className={classes.userName}
                        />
                    </Box>
                    <Box className={classes.container}>
                        <TextInput source="login"
                                   options={{autoComplete: 'none'}}
                                   label="Логин"
                                   disabled={!permissions.isAdmin}
                                   validate={[maxLength(20), required()]}
                                   className={classes.userLogin}
                        />
                    </Box>
                    <>
                        <Box className={classes.container}>
                            <SelectInput className={classes.userDashboards}
                                         source="role"
                                         choices={USER_ROLE_CHOICES}
                                         label="Роль"
                                         defaultValue={USER_ROLE.KEEPER}
                                         disabled={!permissions.isAdmin}
                                         isRequired
                            />
                        </Box>
                        {!isCurrentUser && <Box className={classes.container}>
                            <BooleanInput label="Заблокирована" source="isDisabled"/>
                        </Box>}
                    </>
                    <Box className={classes.container}>
                        <TextInput source="password"
                                   options={{autoComplete: 'none'}}
                                   label="Пароль"
                                   className={classes.userPassword}
                                   type={!hasConfirm ? 'text' : 'password'}
                                   InputProps={{
                                       endAdornment: (
                                           <InputAdornment position="end">
                                               <IconButton onClick={() => setHasConfirm(!hasConfirm)}>
                                                   {!hasConfirm ? <Visibility/> : <VisibilityOff/>}
                                               </IconButton>
                                           </InputAdornment>
                                       ),
                                   }}
                        />
                    </Box>
                    {hasConfirm && <Box className={classes.container}>
                        <PasswordInput source="passwordConfirm"
                                       options={{autoComplete: 'none'}}
                                       label="Подтверждение пароля"
                                       className={classes.userPassword}
                        />
                    </Box>}
                </Box>
            </Box>
        </SimpleForm>
    )
}

export default UserDetailsForm;
import {makeStyles} from "@material-ui/core/styles";

export const showStyles = makeStyles({
    description: {
        height: "80vh",
        overflow: "scroll",
        border: 'solid 1px rgba(0,0,0,0.2)',
        padding: '1em',
        borderRadius: '5px'
    },
    audio: {
        width: '100%'
    },
    container: {
        textAlign: "center",
        display: "flex",
        alignContent: 'space-between'
    },
    textEditor: {
        minHeight: '500px'
    }
})
import {makeStyles} from "@material-ui/core/styles";

export const useCLassesStyles = makeStyles({
    bioLink: {
        background: '#f1f1f1' + "!important",
        fontSize: '18pt'+'!important',
        color: 'black'+'!important',
        // fontWeight: 400 + '!important',
        textDecoration: 'none',
        transition: '200ms',
        // border: 'solid 1px black',
        width: '100%',
        height: '100%',
        "&&:hover" : {
            background: '#a9a9a9' + '!important',
        }
    }
})

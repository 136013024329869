const pageNaming = {
    users: {
        list: {
            header: 'Список Пользователей',
            description: 'Вы можете просматривать список всех Пользователей, ' +
                'редактировать их персональные данные, добавлять новых Пользователей, ' +
                'а также осуществлять поиск по учетным записям'
        },
        create: {
            header: 'Создание новой учетной записи',
            description: `Вы можете добавить Пользователей`
        },
        edit: {
            header: 'Настройки учетной записи',
            description: 'Вы можете редактировать персональные данные, заблокировать или удалить сотрудника'
        },
        show: {
            header: 'Личный кабинет Пользователя',
            description: 'Вы можете просмотреть свои персональные данные и при необходимости изменить пароль'
        }
    },
    classes: {
        list: {
            header: 'Список классов',
            description: 'Вы можете просматривать список всех классов, ' +
                'редактировать, добавить новые',
        },
        create: {
            header: 'Создание нового класса',
            description: `Вы можете добавить новый класс`
        },
        edit: {
            header: 'Редактирование класса',
            description: 'Вы можете редактировать класс'
        }
    },
    writers: {
        list: {
            header: 'Список писателей',
            description: 'Вы можете просматривать список всех писателей, ' +
                'редактировать, добавлять новые, ',
        },
        create: {
            header: 'Добавить нового писателя',
            description: `Вы можете добавить нового писателя`
        },
        edit: {
            header: 'Редактирование данных писателя',
            description: 'Вы можете данные писателя'
        }
    },
    books: {
        list: {
            header: 'Список произведений',
            description: 'Вы можете просматривать список всех произведений, ' +
                'редактировать, добавлять новые, ',
        },
        create: {
            header: 'Добавление нового произведения',
            description: `Вы можете добавить новое произведение`
        },
        edit: {
            header: 'Редактирование данных произведения',
            description: 'Вы можете редактировать данные произведения'
        }
    }
}
export default pageNaming;
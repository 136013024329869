import {Edit} from "react-admin";
import ClassesForm from "./FormClasses";
import pageNaming from "../../pageNaming";
import {PageTitle} from "../../components/PageTitle";
import React from "react";
import {EditToolbar} from "../../components/EditToolbar";
import {Container} from "@material-ui/core";

export const EditClasses = (props) => {
    return <Container>
        <PageTitle header={pageNaming.classes.edit.header}
                   description={pageNaming.classes.edit.description}
        />
        <Edit {...props}
              mutationMode="pessimistic"
        >
            <ClassesForm {...props} isNew={false} toolbar={<EditToolbar/>}/>
        </Edit>
    </Container>
}

import {BulkDeleteWithConfirmButton, Datagrid, List, SearchInput, TextField} from "react-admin";
import * as React from "react";
import {ResearchToolkitPagination} from "../../components/Pagination";
import EmptyPage from "../../components/EmptyPage";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import {Container} from "@material-ui/core";

const ListClasses = props => {
    const filters = [
        <SearchInput
            source="name"
            alwaysOn
        />
    ];

    return <Container>
        <PageTitle header={pageNaming.classes.list.header}
                   description={pageNaming.classes.list.description}
        />
        <List {...props}
              title="Классы"
              bulkActionButtons={!props?.readOnly && <BulkDeleteWithConfirmButton confirmTitle={'Удаление классов'}
                                                                                  confirmContent={'Вы уверены, что хотите удалить выбранные классы?'}
              />}
              exporter={false}
              pagination={<ResearchToolkitPagination/>}
              empty={<EmptyPage header='Нет классов'
                                description='Хотите добавить новый класс?'/>}
              filters={filters}
              sort={{ field: 'name', order: 'ASC' }}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="name" label="Класс"/>
            </Datagrid>
        </List>
    </Container>;
}

export default ListClasses;

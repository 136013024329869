import {Datagrid, FunctionField, List, SearchInput, TextField, useRedirect} from "react-admin";
import {ROUTE_URLS} from "../../api/constants";
import React from "react";
import {Container} from "@mui/material";
import {PageTitle} from "../../components/PageTitle";
import EmptyPage from "../../components/EmptyPage";
import {ResearchToolkitPagination} from "../../components/Pagination";
import {Link} from "react-router-dom";
import {useCLassesStyles} from "./classesStyles";
import {TableCell, TableHead, TableRow} from "@material-ui/core";

export const ShowClasses = (props) => {
    const styleClasses = useCLassesStyles()
    const redirect = useRedirect();
    const classesId = props.id

    const filters = [
        <SearchInput
            source="fullName"
            alwaysOn
        />
    ];

    const DatagridHeader = ({writerId}) => (
        <TableHead>
            <TableRow>
                <TableCell component={Link}
                           to={`/${ROUTE_URLS.WRITER}/${writerId}/show`}
                           className={styleClasses.bioLink}>
                    Биография
                </TableCell>
            </TableRow>
        </TableHead>
    );

    const redirectToBookShow = (id) => {
        redirect(`/${ROUTE_URLS.BOOKS}/${id}/show`)
    }
    const redirectToWriterShow = (id) => {
        redirect(`/${ROUTE_URLS.WRITER}/${id}/show`)
    }

    return <Container>
        <PageTitle header='Выберите произведение'
                   backUrl={`/${ROUTE_URLS.MAIN}`}
                   backLabel={'Назад к выбору класса'}
        />
        <List resource={ROUTE_URLS.API_COMMON_WRITERS}
              basePath={ROUTE_URLS.API_COMMON_WRITERS}
              bulkActionButtons={false}
              actions={false}
              sort={{field: 'surname', order: 'ASC'}}
              filter={{classesId}}
              empty={<EmptyPage header={'Произведения пока не добавлены для выбранного класса'}
                                description={'Скоро всё будет добавлено'}
                                notHasCreate
              />}
              filters={filters}
              pagination={<ResearchToolkitPagination/>}
        >
            <Datagrid isRowExpandable={(record) => !record.bookId}
                      optimized
                      header={<></>}
                      rowClick={(id, basePath, record) => record.bookId ? redirectToBookShow(id) : "expand"}
                      expand={props => {
                          const writerId = props.id
                          return <List resource={ROUTE_URLS.API_COMMON_BOOKS}
                                       basePath={ROUTE_URLS.API_COMMON_BOOKS}
                                       bulkActionButtons={false}
                                       actions={false}
                                       sort={{field: 'name', order: 'ASC'}}
                                       filter={{classesId, writerId}}
                                       empty={<EmptyPage header={'Произведения пока не добавлены для выбранного автора'}
                                                         description={'Скоро всё будет добавлено'}
                                                         notHasCreate
                                       />}
                                       pagination={<ResearchToolkitPagination/>}
                          >
                              <Datagrid header={<DatagridHeader writerId={writerId}/>}
                                        rowClick={(id) => {redirectToBookShow(id)}}>
                                  <TextField source='name' label='Название произведения' sortable={false}/>
                              </Datagrid>
                          </List>
                      }}
            >
                <FunctionField
                    render={record => {
                        return !!record.bookName ? record.bookName : `${record.surname} ${record.name}  ${record.middleName}`
                    }}
                />
            </Datagrid>
        </List>
    </Container>
}

import * as React from "react";
import {Create, SaveButton, Toolbar, useNotify,} from 'react-admin';
import UserDetailsForm from "./UserDetailsForm";
import {FloppyIcon} from "../../icons/iconsFactory";
import {Container} from "@mui/material";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";

export const CreateUser = props => {

    const notify = useNotify();

    const onFailure = (error) => {
        notify(error.message, {type: 'warning'});
    };

    const SaveToolbar = (props) => {
        const {
            handleSubmit,
            handleSubmitWithRedirect,
            onSave,
            invalid,
            pristine,
            saving
        } = props;

        return <Toolbar {...props} >
            <SaveButton icon={<FloppyIcon/>}
                        handleSubmit={handleSubmit}
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        onSave={onSave}
                        invalid={invalid}
                        pristine={pristine}
                        saving={saving}
                        variant="text"
                        submitOnEnter={false}/>
        </Toolbar>;
    }

    return <Container>
        <PageTitle header={pageNaming.users.create.header}
                   description={pageNaming.users.create.description}
        />
        <Create {...props} onFailure={onFailure} mutationMode="pessimistic">
            <UserDetailsForm isNew={true}
                             toolbar={<SaveToolbar/>}
                             permissions={props.permissions}/>
        </Create>;
    </Container>
}
import EmptyPage from "../../components/EmptyPage";
import {Datagrid, FunctionField, List, ReferenceField, SearchInput, TextField} from "react-admin";
import {ROUTE_URLS} from "../../api/constants";
import * as React from "react";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import {ResearchToolkitPagination} from "../../components/Pagination";
import {Container} from "@material-ui/core";

export const ListBooks = (props) => {
    const filters = [
        <SearchInput
            source="name"
            alwaysOn
        />
    ];
    return <Container>
        <PageTitle header={pageNaming.books.list.header}
                   description={pageNaming.books.list.description}
        />
        <List {...props}
              empty={<EmptyPage header={'Не добавлено ни одного произведения'}
                                description={''}
              />}
              exporter={false}
              filters={filters}
              sort={{field: 'name', order: 'ASC'}}
              pagination={<ResearchToolkitPagination/>}
        >
            <Datagrid rowClick='edit'>
                <TextField source='name' label='Название'/>
                <ReferenceField label="Класс"
                                source="classesId"
                                reference={ROUTE_URLS.CLASSES}
                                link={false}
                                sortable={false}
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Писатель"
                                source="writerId"
                                reference={ROUTE_URLS.WRITER}
                                link={false}
                                sortable={false}
                >
                    <FunctionField render={record => `${record.surname} ${record.name}  ${record.middleName}`}/>
                </ReferenceField>
            </Datagrid>
        </List>
    </Container>

}

import {getToolbarStyles} from "../themes/editViewToolbarStyles";
import {FloppyIcon, TrashIcon} from "../icons/iconsFactory";
import {DeleteButton, SaveButton, Toolbar} from "react-admin";
import CancelButton from "./CancelButton";

export const EditToolbar = (props) => {
    return <Toolbar {...props} classes={getToolbarStyles()}>
        <SaveButton icon={<FloppyIcon/>}
                    label="Сохранить"
                    variant="text"
                    onSave={props.onSave}
                    disabled={props.pristine}/>
        <CancelButton/>
        <DeleteButton icon={<TrashIcon/>}
                      mutationMode="pessimistic"
                      confirmTitle={'Удалить выбранные элементы?'}/>
    </Toolbar>
}
import {FloppyIcon} from "../icons/iconsFactory";
import {SaveButton, Toolbar} from "react-admin";
import * as React from "react";

export const CreateToolbar = ({
                                  handleSubmit,
                                  handleSubmitWithRedirect,
                                  onSave,
                                  invalid,
                                  pristine,
                                  saving,
                                  ...props
                              }) => {
    return <Toolbar {...props}>
        <SaveButton icon={<FloppyIcon/>}
                    handleSubmit={handleSubmit}
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    onSave={onSave}
                    invalid={invalid}
                    pristine={pristine}
                    saving={saving}
                    variant="text"
                    submitOnEnter={false}/>
    </Toolbar>
}
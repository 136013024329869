import * as React from "react";
import {Admin, Resource} from 'react-admin';
import {UsersIcon} from "./icons/iconsFactory";
import {mainTheme} from "./themes/mainTheme";
import authProvider from "./auth/authProvider";
import {PAGE_NAMES, ROUTE_URLS} from "./api/constants";
import {setPageName} from "./api/utility";
import messages from "./messages";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {HashRouter} from "react-router-dom";
import ResearcherToolkitLayout from "./components/layout/ResearcherToolkitLayout";
import {EditUser} from "./routes/users/EditUser";
import {ListUsers} from "./routes/users/ListUsers";
import {CreateUser} from "./routes/users/CreateUser";
import {LogoutButton} from "./components/logoutButton/LogoutButton";
import {EditClasses} from "./routes/classes/EditClasses";
import ListClasses from "./routes/classes/ListClasses";
import {CreateClasses} from "./routes/classes/CreateClasses";
import {EditWriter} from "./routes/writer/EditWriter";
import {CreateWriter} from "./routes/writer/CreateWriter";
import {ListWriter} from "./routes/writer/ListWriter";
import filDataProvider from "./api/dataProvider";
import {EditBook} from "./routes/book/EditBook";
import {ListBooks} from "./routes/book/ListBooks";
import {CreateBook} from "./routes/book/CreateBook";
import {
    Attribution as AttributionIcon,
    MenuBook as MenuBookIcon,
    SentimentSatisfiedAlt as SentimentSatisfiedAltIcon
} from '@mui/icons-material';
import {LoginPage} from "./routes/auth/LoginPage";
import {Home} from "./routes/home/Home";
import {ShowBook} from "./routes/book/ShowBook";
import {ShowClasses} from "./routes/classes/ShowClasses";
import {ShowWriter} from "./routes/writer/ShowWriter";
import {Footer} from "./components/Footer";

const i18nProvider = polyglotI18nProvider(() => messages, 'ru');

const initialState = {
    admin: {
        ui: {
            sidebarOpen: authProvider.isAdmin(),
            viewVersion: 0
        }
    }
}

const App = () => {
    return <HashRouter>
        <Admin dataProvider={filDataProvider}
               authProvider={authProvider}
               initialState={initialState}
               i18nProvider={i18nProvider}
               theme={mainTheme}
               appLayout={ResearcherToolkitLayout}
               logoutButton={!authProvider.isAnonymous() && LogoutButton}
               loginPage={LoginPage}
               disableTelemetry
        >
            {permissions => [
                <Resource name={ROUTE_URLS.MAIN}
                          options={{label: 'Общая таблица'}}
                          list={props => {
                              return Home(props);
                          }}
                />,
                permissions.isAdmin && <Resource name={ROUTE_URLS.USERS}
                                                 options={{label: 'Пользователи'}}
                                                 edit={props => {
                                                     setPageName(PAGE_NAMES.USER)
                                                     return EditUser({...props, permissions})
                                                 }}
                                                 list={props => {
                                                     setPageName(PAGE_NAMES.USERS_LIST)
                                                     return ListUsers(props)
                                                 }}
                                                 create={props => {
                                                     setPageName(PAGE_NAMES.USER_CREATE)
                                                     return CreateUser({...props, permissions})
                                                 }}
                                                 icon={UsersIcon}
                />,
                <Resource name={ROUTE_URLS.CLASSES}
                          options={{label: 'Классы'}}
                          edit={props => {
                              setPageName(PAGE_NAMES.USER)
                              return EditClasses({...props, permissions})
                          }}
                          list={props => {
                              setPageName(PAGE_NAMES.USERS_LIST)
                              return ListClasses(props)
                          }}
                          create={props => {
                              setPageName(PAGE_NAMES.USER_CREATE)
                              return CreateClasses({...props, permissions})
                          }}
                          show={props => {
                              console.log('classes show')
                              setPageName(PAGE_NAMES.USER_CREATE)
                              return ShowClasses(props)
                          }}
                          icon={SentimentSatisfiedAltIcon}
                />,
                <Resource name={ROUTE_URLS.WRITER}
                          options={{label: 'Писатели'}}
                          edit={props => {
                              setPageName(PAGE_NAMES.USER)
                              return EditWriter({...props, permissions})
                          }}
                          list={props => {
                              setPageName(PAGE_NAMES.USERS_LIST)
                              return ListWriter(props)
                          }}
                          create={props => {
                              setPageName(PAGE_NAMES.USER_CREATE)
                              return CreateWriter({...props, permissions})
                          }}
                          show={props => {
                              console.log('writer show')
                              setPageName(PAGE_NAMES.USER_CREATE)
                              return ShowWriter(props)
                          }}
                          icon={AttributionIcon}
                />,
                <Resource name={ROUTE_URLS.BOOKS}
                          options={{label: 'Произведения'}}
                          edit={props => {
                              setPageName(PAGE_NAMES.USER)
                              return EditBook({...props, permissions})
                          }}
                          show={props => {
                              setPageName(PAGE_NAMES.USER)
                              return ShowBook({...props, permissions})
                          }}
                          list={props => {
                              setPageName(PAGE_NAMES.USERS_LIST)
                              return ListBooks(props)
                          }}
                          create={props => {
                              setPageName(PAGE_NAMES.USER_CREATE)
                              return CreateBook({...props, permissions})
                          }}
                          icon={MenuBookIcon}
                />,
                <Resource name={ROUTE_URLS.API_COMMON_CLASSES}/>,
                <Resource name={ROUTE_URLS.API_COMMON_WRITERS}/>,
                <Resource name={ROUTE_URLS.API_COMMON_BOOKS}/>,
            ]}
        </Admin>
        <Footer />
    </HashRouter>
};

export default App;

import {Create, useRedirect} from "react-admin";
import ClassesForm from "./FormClasses";
import pageNaming from "../../pageNaming";
import {PageTitle} from "../../components/PageTitle";
import React from "react";
import {ROUTE_URLS} from "../../api/constants";
import {CreateToolbar} from "../../components/CreateToolbar";
import {Container} from "@material-ui/core";

export const CreateClasses = (props) => {
    const redirect = useRedirect()
    return <Container>
        <PageTitle header={pageNaming.classes.create.header}
                   description={pageNaming.classes.create.description}
        />
        <Create {...props}
                onSuccess={() => redirect(`/${ROUTE_URLS.CLASSES}`)}
        >
            <ClassesForm {...props} isNew={true} toolbar={<CreateToolbar/>}/>
        </Create>
    </Container>

}

import {AppBar, MenuItemLink, useRefresh, UserMenu} from "react-admin";
import auth from "../../auth/authProvider";
import authProvider from "../../auth/authProvider";
import {Box} from '@material-ui/core';
import {useAppBarStyles} from "./appBarStyles";
import {RefreshIcon, UserIcon} from "../../icons/iconsFactory";
import {ROUTE_URLS} from "../../api/constants";
import {IconButton} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {LocalLibrary as LocalLibraryIcon} from '@mui/icons-material';

const useAppbarStyles = makeStyles(() => {
    if (authProvider.isAdmin()) {
        return {};
    }
    //для не админов скрываем кнопку управления левым меню
    return {
        menuButton: {
            visibility: 'hidden'
        }
    }
});

const RefreshButton = () => {
    const refresh = useRefresh();
    return <IconButton onClick={() => refresh()} color="inherit">
        <RefreshIcon/>
    </IconButton>;
};

const ResearcherToolkitAppBar = (props) => {
    const classes = useAppBarStyles();
    const appbarClasses = useAppbarStyles();

    if (!authProvider.isAdmin()) {
        if(document.querySelectorAll("div[class^='RaSidebar-fixed']").length > 0) {
            document.querySelectorAll("div[class^='RaSidebar-fixed']")[0].style = "display: none"
        }
    }

    const ResearcherToolkitUserMenu = (props) => {
        return <UserMenu icon={<UserIcon/>} {...props}>
            {!authProvider.isAnonymous() ? <MenuItemLink
                    to={`/${ROUTE_URLS.USERS}/${auth.getCurrentUserId()}`}
                    primaryText="Мой Профиль"
                />
                : <MenuItemLink
                    to={`/${ROUTE_URLS.LOGIN}`}
                    primaryText="Войти"
                />}
        </UserMenu>;
    };

    return <AppBar {...props} userMenu={<ResearcherToolkitUserMenu/>} classes={appbarClasses}>
        <a href={`/#/home`} className={classes.image}><LocalLibraryIcon className={classes.icon}/></a>
        <a href={`/#/home`} className={classes.caption}>Мелодия слов</a>
        {
            !authProvider.isAnonymous() && <Box>
                {authProvider.getCurrentUserName()}
            </Box>}
        <RefreshButton/>
    </AppBar>
};

export default ResearcherToolkitAppBar;

import React from 'react'
import {Box, makeStyles, Typography} from "@material-ui/core";
import {Fade, Stack, Tooltip} from "@mui/material";
import {Button} from "react-admin";
import {ArrowBack as ArrowBackIcon, Help as HelpIcon} from "@mui/icons-material";
import {Link} from "react-router-dom";

const useTitleStyles = makeStyles({
    container: {
        paddingLeft: '5%',
        paddingTop: '2%',
        marginBottom: '1em',
        display: "flex",
        justifyContent: 'space-between'
    },
    description: {
        color: '#ffffff',
        fontStyle: 'italic',
        textAlign: "justify"
    }
})
export const PageTitle = ({header, subHeader, description, backLabel, backUrl}) => {
    const descriptionTooltip = () => {
        return description && <span>
                &nbsp;
                <Tooltip placement='bottom'
                         TransitionComponent={Fade}
                         TransitionProps={{timeout: 300}}
                         componentsProps={{
                             tooltip: {
                                 sx: {
                                     maxWidth: 'fit-content',
                                 },
                             },
                         }}
                         title={<div>
                             {typeof description === 'string'
                                 ? description.split('\n').map((descriptionRow, key) =>
                                     <Typography className={classes.description}
                                                 key={key}
                                     >
                                         {descriptionRow}
                                     </Typography>)
                                 : <Typography className={classes.description}>
                                     {description}
                                 </Typography>
                             }
                         </div>}
                >
                <HelpIcon/>
            </Tooltip>
        </span>
    }
    const classes = useTitleStyles()
    return <Stack direction='row' className={classes.container}>
        <Stack>
            {header &&
                <span>
                    <Typography variant={'h4'}>
                        {header}
                        {descriptionTooltip()}
                    </Typography>
                </span>
            }
            {subHeader && <span>
                <Typography variant={'h6'}>
                    {subHeader}
                    {header == null && descriptionTooltip()}
                </Typography>
            </span>}
        </Stack>
        <Box>
            {(backLabel || backUrl) && <Button startIcon={<ArrowBackIcon/>}
                                               component={Link}
                                               label={`${backLabel || 'Назад'}`}
                                               to={{
                                                   pathname: backUrl,
                                               }}
                                               onClick={() => backUrl ? '' : window.history.back()}
            />}
        </Box>
    </Stack>
}
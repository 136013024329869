import {Logout} from "react-admin";
import {LogoutIcon} from "../../icons/iconsFactory";
import {logoutStyles} from "./styles";

export const LogoutButton = props => {
    const styles = logoutStyles();
    return (<Logout {...props}
                    icon={<LogoutIcon color={'#929da6'}/>}
                    className={styles.logoutButton}
        />
    );
}
import {Box, makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";

const commonParams = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
}

const useStyles = makeStyles({
    background: {
        backgroundImage: props => `url(${props.image})`,
        filter: props => `brightness(${props.brightness}) blur(${props.blur}px)`,
        ...commonParams
    },
    backBoard: {
        backgroundImage: props => `url(${props.image})`,
        filter: props => `brightness(${props.brightness})`,
        ...commonParams
    }
})

const Background = ({image, brightness = 1, blur = 0}) => {
    const classes = useStyles({image, brightness, blur})
    return (
        <Box>
            <Box className={classes.backBoard}/>
            <Box className={classes.background}/>
        </Box>
    )
}
Background.propTypes = {
    image: PropTypes.string.isRequired,
    brightness: PropTypes.number,
    blur: PropTypes.number
}
export default Background
import React from "react";
import {Datagrid, List, TextField, useRedirect} from 'react-admin'
import {ROUTE_URLS} from "../../api/constants";
import {Container, Typography} from "@material-ui/core";
import {PageTitle} from "../../components/PageTitle";
import {ResearchToolkitPagination} from "../../components/Pagination";

export const Home = () => {
    const redirect = useRedirect()
    return (
        <Container>
            <PageTitle header={"Выберите класс"}/>
            <List resource={ROUTE_URLS.API_COMMON_CLASSES}
                  basePath={ROUTE_URLS.API_COMMON_CLASSES}
                  bulkActionButtons={false}
                  actions={false}
                  sort={{field: 'name', order: 'ASC'}}
                  empty={<Typography>Ожидайте добавления новой информации</Typography>}
                  pagination={<ResearchToolkitPagination/>}
            >
                <Datagrid optimized
                          header={<></>}
                          rowClick={(id) => {
                              redirect(`/${ROUTE_URLS.CLASSES}/${id}/show`)
                          }}
                >
                    <TextField label='Класс' source='name'/>
                </Datagrid>
            </List>
        </Container>
    )
}

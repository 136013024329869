import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as UsersIconSvg} from "./svg/users-line.svg";
import {ReactComponent as UserIconSvg} from "./svg/user-line.svg";
import {ReactComponent as RefreshIconSvg, ReactComponent as ConvertDataSvg} from "./svg/refresh-line.svg";
import {ReactComponent as TrashIconSvg} from "./svg/trash-line.svg";
import {ReactComponent as FloppyIconSvg} from "./svg/floppy-line.svg";
import {ReactComponent as RedoIconSvg} from "./svg/redo-line.svg";
import {ReactComponent as PlusIconSvg} from "./svg/plus-circle-line.svg";
import {ReactComponent as FilterIconSvg} from "./svg/filter-line.svg";
import {ReactComponent as LogoutIconSvg, ReactComponent as PowerLineIconSvg} from "./svg/power-line.svg";
import {ReactComponent as DashboardIconSvg} from "./svg/analytics-line.svg";
import {ReactComponent as ImportSourceSvg} from "./svg/tasks-line.svg";
import {ReactComponent as ErrorIconSvg} from "./svg/error-standard-line.svg";
import {ReactComponent as SuccessIconSvg} from "./svg/success-standard-line.svg";
import {ReactComponent as SectionIconSvg} from "./svg/justify-text-line.svg";
import {ReactComponent as ExpandArrowIconSvg} from "./svg/angle-line.svg";
import {ReactComponent as CalendarSvg} from "./svg/calendar-line.svg";

export const UsersIcon = () => createIcon(UsersIconSvg, 'Пользователи');

export const TrashIcon = () => createIcon(TrashIconSvg, 'Удалить', {color: '#e82129'});

export const FloppyIcon = () => createIcon(FloppyIconSvg, 'Сохранить');

export const RedoIcon = () => createIcon(RedoIconSvg, 'Отменить');

export const PlusIcon = () => createIcon(PlusIconSvg, 'Создать');

export const PowerLineIcon = () => createIcon(PowerLineIconSvg, 'Запустить');

export const FilterIcon = () => createIcon(FilterIconSvg, 'Добавить фильтр');

export const UserIcon = () => createIcon(UserIconSvg, 'Пользователь');

export const DashboardIcon = () => createIcon(DashboardIconSvg, 'Аналитические панели');

export const ImportSourceIcon = () => createIcon(ImportSourceSvg, 'Импорт данных');

export const ConvertDataIcon = () => createIcon(ConvertDataSvg, 'Преобразование данных', {rotate: "-135deg"});

export const SectionIcon = () => createIcon(SectionIconSvg, 'Разделы');

export const CalendarIcon = () => createIcon(CalendarSvg, 'Логгер');

export const LogoutIcon = (color) => createIcon(LogoutIconSvg, 'Выход', {color: color});

export const RefreshIcon = () => createIcon(RefreshIconSvg, 'Обновить');

const ImportStatusIconSize = {
    height: '0.75em',
    width: '0.75em'
};

export const ErrorIcon = () => createIcon(ErrorIconSvg, '', ImportStatusIconSize);

export const SuccessIcon = () => createIcon(SuccessIconSvg, '', ImportStatusIconSize);

export const ExpandArrow = () => createIcon(ExpandArrowIconSvg, '', {rotate: '90deg'});

const createIcon = (svg, title, styles) =>
    <SvgIcon component={svg} viewBox="0 0 36 36" fontSize="medium" title={title || ''} sx={styles}/>;


import {useState} from "react";
import {DeleteButton, Edit, SaveButton, Toolbar, useNotify, useRedirect} from 'react-admin';
import ConfirmPasswordDialog from "./ConfirmPasswordModal";
import UserDetailsForm from "./UserDetailsForm";
import CancelButton from "../../components/CancelButton";
import {FloppyIcon, TrashIcon} from "../../icons/iconsFactory";
import authProvider from "../../auth/authProvider";
import {getToolbarStyles} from "../../themes/editViewToolbarStyles";
import {ROUTE_URLS} from "../../api/constants";
import {Container} from "@mui/material";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";

export const EditUser = props => {
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const notify = useNotify();
    const redirect = useRedirect();

    const isCurrentUser = () => props.id === authProvider.getCurrentUserId();

    const onFailure = (error) => {
        notify(error.message, {type: 'warning'});
    };

    const EditToolbar = props => {
        const {
            handleSubmit,
            handleSubmitWithRedirect,
            onSave,
            invalid,
            pristine,
            saving,
            isAdmin,
        } = props;

        return <Toolbar {...props} classes={getToolbarStyles()}>
            <SaveButton label="Сохранить"
                        icon={<FloppyIcon/>}
                        variant="text"
                        onSave={isCurrentUser() ? () => setShowConfirmationDialog(true) : onSave}
                        disabled={pristine}/>
            <CancelButton redirectPath={isAdmin ? props.basePath : ROUTE_URLS.HOME}/>
            {!isCurrentUser() && <DeleteButton icon={<TrashIcon/>}
                                               mutationMode="pessimistic"
                                               confirmTitle={`Удалить пользователя "${props.record.name}"`}/>}
            <ConfirmPasswordDialog open={showConfirmationDialog}
                                   onClose={() => setShowConfirmationDialog(false)}
                                   handleSubmit={handleSubmit}
                                   handleSubmitWithRedirect={handleSubmitWithRedirect}
                                   onSave={onSave}
                                   invalid={invalid}
                                   pristine={pristine}
                                   saving={saving}
            />
        </Toolbar>
    }

    const onSuccess = () => {
        if (props.permissions?.isAdmin) {
            redirect(`/${ROUTE_URLS.USERS}`);
        }
    };

    return <Container>
        <PageTitle header={pageNaming.users.edit.header}
                   description={pageNaming.users.edit.description}
        />
        <Edit {...props}
              mutationMode="pessimistic"
              onFailure={onFailure}
              onSuccess={onSuccess}
        >
            <UserDetailsForm isNew={false}
                             toolbar={<EditToolbar isAdmin={props.permissions?.isAdmin}/>}
                             isCurrentUser={isCurrentUser()}
                             permissions={props.permissions}/>
        </Edit>
    </Container>
}
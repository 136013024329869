import jsonServerProvider from 'ra-data-json-server';
import {ROUTE_URLS} from "./constants";
import sendPostData, {httpClient} from "./httpClient";

const dataProvider = jsonServerProvider('', httpClient);

/**
 * кастомный dataProvider нужен для того, чтобы конвертировать файлы в base64
 * https://marmelab.com/react-admin/DataProviders.html#handling-file-uploads
 * @type {{[p: string]: any, deleteMany: (resource: string, params: DeleteManyParams) => Promise<DeleteManyResult>, updateMany: (resource: string, params: UpdateManyParams) => Promise<UpdateManyResult>, getList: <RecordType=Record extends Record>(resource: string, params: GetListParams) => Promise<GetListResult<RecordType>>, getMany: <RecordType=Record extends Record>(resource: string, params: GetManyParams) => Promise<GetManyResult<RecordType>>, getManyReference: <RecordType=Record extends Record>(resource: string, params: GetManyReferenceParams) => Promise<GetManyReferenceResult<RecordType>>, getOne: <RecordType=Record extends Record>(resource: string, params: GetOneParams) => Promise<GetOneResult<RecordType>>, update: ((function(*, *): (Promise<UpdateResult<Record>>))|*), create: <RecordType=Record extends Record>(resource: string, params: CreateParams) => Promise<CreateResult<RecordType>>, delete: <RecordType=Record extends Record>(resource: string, params: DeleteParams) => Promise<DeleteResult<RecordType>>}}
 */
const filDataProvider = {
    ...dataProvider,
    update: (resource, params) => {
        return fileUploadCustom(resource, params, dataProvider.update, "PUT");
    },
    create: (resource, params) => {
        return fileUploadCustom(resource, params, dataProvider.create, "POST");
    }
}

export default filDataProvider;

const fileUploadCustom = (resource, params, fallbackFunction, method) => {
    if (!isFileUploadAction(resource)) {
        return fallbackFunction(resource, params);
    }
    const {image, audio, ...data} = params.data
    return sendPostData(`/${resource}${method === 'PUT' ? `/${params.id}` : ''}`, data, image?.rawFile, audio?.rawFile, method)
}


const isFileUploadAction = (resource) => {
    return [ROUTE_URLS.WRITER, ROUTE_URLS.BOOKS].includes(resource);
}
import {Datagrid, FunctionField, List, SearchInput} from "react-admin";
import EmptyPage from "../../components/EmptyPage";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import * as React from "react";
import {ResearchToolkitPagination} from "../../components/Pagination";
import {Container} from "@material-ui/core";

export const ListWriter = (props) => {
    const filters = [
        <SearchInput
            source="fullName"
            alwaysOn
        />
    ];

    return <Container>
        <PageTitle header={pageNaming.writers.list.header}
                   description={pageNaming.writers.list.description}
        />
        <List {...props}
              empty={<EmptyPage header={'Не добавлено ни одного писателя'}
                                description={''}
              />}
              exporter={false}
              sort={{ field: 'surname', order: 'ASC' }}
              pagination={<ResearchToolkitPagination/>}
              filters={filters}
        >
            <Datagrid rowClick='edit'>
                <FunctionField label='ФИО писателя'
                               source='surname'
                               render={record => `${record.surname} ${record.name}  ${record.middleName}`}
                />
            </Datagrid>
        </List>
    </Container>
}

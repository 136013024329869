import {LOCAL_STORAGE_AUTH, LOCAL_STORAGE_IS_REQUIRE_LOGIN, USER_ROLE} from '../api/constants';
import {login} from '../api/auth';
import messages from "../messages";

export function parseJwt(token) {
    if (!token) {
        return false;
    }
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const getUserRole = () => parseJwt(localStorage.getItem(LOCAL_STORAGE_AUTH)).userRole;

const authProvider = {
    login: ({username, password}) => {
        return login(username, password).then(response => {
            localStorage.setItem(LOCAL_STORAGE_IS_REQUIRE_LOGIN, 'false');
            localStorage.setItem(LOCAL_STORAGE_AUTH, response.headers['authorization']);
        }).catch((e) => {
            if (!e.response || e.response.status !== 401) {
                throw new Error(messages.error.applicationUnavailable);
            } else {
                throw new Error(messages.error.loginFailed);
            }
        });
    },

    logout: () => {
        localStorage.removeItem(LOCAL_STORAGE_AUTH);
        localStorage.removeItem(LOCAL_STORAGE_IS_REQUIRE_LOGIN);
        return Promise.resolve();
    },

    checkError: ({status}) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem(LOCAL_STORAGE_AUTH);
            localStorage.setItem(LOCAL_STORAGE_IS_REQUIRE_LOGIN, 'true');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getCurrentUserId: () => {
        return parseJwt(localStorage.getItem(LOCAL_STORAGE_AUTH)).userId;
    },
    getCurrentUserName: () => {
        return parseJwt(localStorage.getItem(LOCAL_STORAGE_AUTH)).userFullName;
    },
    getCurrentUserRole: () => {
        return getUserRole()
    },
    checkAuth: (params) => {
        if (localStorage.getItem(LOCAL_STORAGE_IS_REQUIRE_LOGIN) === 'true') {
            return Promise.reject()
        }
        if (window.location.hash.includes('show') || window.location.hash.includes('home')) {
            return Promise.resolve();
        }
        if (localStorage.getItem(LOCAL_STORAGE_AUTH)) {
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    },
    isAdmin: () => {
        return getUserRole() === USER_ROLE.ADMIN
    },
    isAnonymous: () => {
        return getUserRole() !== USER_ROLE.ADMIN
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: (params) => {
        return Promise.resolve({
            isAdmin: getUserRole() === USER_ROLE.ADMIN,
        })
    },
};

export default authProvider;
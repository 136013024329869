import {BOOKS_SERVICE_HOST} from "../settings/appsettings";

export const LOCAL_STORAGE_AUTH = 'auth'
export const LOCAL_STORAGE_IS_REQUIRE_LOGIN = 'require-login'

export const API_PATH_PREFIX = 'fil';

export const applicationName = "FIL"
export const PAGE_NAMES = {
    USERS_LIST:         `Пользователи`,
    USER_CREATE:        `Новый пользователь`,
    USER:               `Пользователь`,
    LOGIN:              `Вход`
}

export const USER_ROLE = {
    ADMIN: 'ADMIN',
    USER: 'USER',
}

export const USER_ROLE_CHOICES = [
    {id: USER_ROLE.ADMIN, name: 'Администратор'},
    {id: USER_ROLE.USER, name: 'Пользователь'}
];

export const ROUTE_URLS = {
    LOGIN: 'login',
    HOME: '/',
    USERS: 'users',
    BOOKS: 'book',
    IMAGE: BOOKS_SERVICE_HOST + '/image',
    AUDIO: BOOKS_SERVICE_HOST + '/audio',
    WRITER: 'writer',
    CLASSES: 'classes',
    MAIN: "home",
    COMMON_CLASSES: "home-classes",
    COMMON_WRITERS: "home-writers",
    COMMON_BOOKS: "home-books",
    API_COMMON_CLASSES: "home/classes",
    API_COMMON_WRITERS: "home/writers",
    API_COMMON_BOOKS: "home/books",

}
import {makeStyles} from "@material-ui/core";

export const authUseStyles = makeStyles({
    container:{
        display: 'flex',
        alignItems: "start",
        justifyContent: "center",
        paddingTop: '10%',
    },
    form: {
        background: "#ffffff",
        // border: "solid 1px",
        borderRadius: "5px",
        padding: "0.5rem",
        display: "flex",
        gap: "1rem",
        width: '20em',
        filter: 'brightness(1)',
        flexDirection: 'column',
    },
    button: {
        color: 'fff',
        backgroundColor: '#1a2936',
        width: '100%',
    },
    login: {
        backgroundColor: 'white',
        borderRadius: '4px',
        marginTop: '6rem',
        overflow: 'hidden',
        // boxShadow: '0px 2px 1px -1px rgba(255,255,255,0.2),' +
        //     '0px 1px 1px 0px rgba(255,255,255,1.14),' +
        //     '0px 1px 3px 0px rgba(255,255,255,0.12)',
        minWidth: '300px'
    },
    textFieldGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '0.5rem',
    }
})
import {makeStyles} from "@material-ui/core";

export const useWriterStyles = makeStyles({
    container: {
        display: "flex",
        flexFlow: 'row',
        gap: '1em'
    },
    description: {
        height: "80vh",
        overflow: "scroll",
        border: 'solid 1px rgba(0,0,0,0.2)',
        padding: '1em',
        borderRadius: '5px'
    },
    showContainer: {
        textAlign: "center",
        display: "flex",
        alignContent: 'space-between'
    },
    imageBox:{
        overflow: "hidden"
    },
    image: {
        maxHeight: '256px',
        maxWidth: '256px'
    }
})
import {Box} from "@mui/material";
import {makeStyles, Typography} from "@material-ui/core";

const useFooterStyles = makeStyles({
    container: {
        backdropFilter: 'blur(5px)',
        padding: "0 2em 10px 2em",
        backgroundColor: 'rgba(255,255,255,0.45)',
        textAlign: 'center',
    },
    content: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: "center"
    }
})

export const Footer = () => {
    const classes = useFooterStyles();
    return <Box className={classes.container}>
        <hr size={1}/>
        <Box className={classes.content}>
            <Typography>
                Обсудить произведения и задать свои вопросы вы можете <a href={'https://vk.com/mezhdy_strok21'}>здесь</a>
            </Typography>
        </Box>
    </Box>
}

import {LOCAL_STORAGE_AUTH, ROUTE_URLS} from "./constants";
import axios from "axios";
import {AUTHENTICATION_SERVICE_HOST, BOOKS_SERVICE_HOST} from "../settings/appsettings";
import PropTypes from "prop-types";
import {fetchUtils} from "react-admin";
import messages from "../messages";

export const httpClient = (url, options = {}) => {
    enrichHeaders(options);

    return fetchUtils.fetchJson(getServiceHost(url) + url, options)
        .then((response) => {
            if (response.headers.get('authorization') && !localStorage.getItem(LOCAL_STORAGE_AUTH)) {
                localStorage.setItem(LOCAL_STORAGE_AUTH, response.headers.get('authorization'));
            }
            return Promise.resolve(response);
        })
        .catch(e => {
            if (e?.message === 'Failed to fetch') {
                throw new Error(messages.error.applicationUnavailable);
            }
            throw e;
        });
};

export const fetch = (url, options = {}) => {
    enrichHeaders(options);

    return axios({
        method: options?.method || 'GET',
        url: getServiceHost(url) + url,
        headers: Object.fromEntries(options?.headers.entries()),
        params: options?.params,
        data: options?.data
    })
}

export const anonymousQuery = axios.create({
    baseURL: AUTHENTICATION_SERVICE_HOST
});

const getServiceHost = url => {
    if (url.startsWith(`/${ROUTE_URLS.AUDIO}`)
        || url.startsWith(`/${ROUTE_URLS.IMAGE}`)
        || url.startsWith(`/${ROUTE_URLS.BOOKS}`)
        || url.startsWith(`/${ROUTE_URLS.WRITER}`)
        || url.startsWith(`/${ROUTE_URLS.CLASSES}`)
        || url.startsWith(`/${ROUTE_URLS.MAIN}`)
    ) {
        return BOOKS_SERVICE_HOST;
    }
    return AUTHENTICATION_SERVICE_HOST;
};

const enrichHeaders = options => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    options.headers.set('authorization', 'Bearer ' + localStorage.getItem(LOCAL_STORAGE_AUTH));
}

const sendPostData = (url, dataBody, image = null, audio = null, method) => {
    const formData = new FormData();
    const json = JSON.stringify(dataBody);
    const data = new Blob([json], {
        type: 'application/json'
    })
    if (image != null) formData.append('image', image)
    if (audio != null) formData.append('audio', audio)
    formData.append('body', data)
    return fetch(url, {
        headers: new Headers({
            'Content-Type': 'multipart/form-data',
            'Accept': 'multipart/form-data'
        }),
        data: formData,
        method
    });
}

sendPostData.propTypes = {
    dataBody: PropTypes.object.isRequired,
    image: PropTypes.array,
    audio: PropTypes.array,
    url: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired
}

export default sendPostData
import {FormWriter} from "./FormWriter";
import {Create, useRedirect} from "react-admin";
import {CreateToolbar} from "../../components/CreateToolbar";
import React from "react";
import {ROUTE_URLS} from "../../api/constants";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import {Container} from "@material-ui/core";

export const CreateWriter = (props) => {
    const redirect = useRedirect()

    return (<Container>
        <PageTitle header={pageNaming.writers.create.header}
                   description={pageNaming.writers.create.description}
        />
        <Create {...props} onSuccess={() => redirect(`/${ROUTE_URLS.WRITER}`)}>
            <FormWriter isNew={true} {...props} toolbar={<CreateToolbar/>}/>
        </Create>
    </Container>)
}

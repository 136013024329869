import {FormWriter} from "./FormWriter";
import {Edit} from "react-admin";
import React from "react";
import {EditToolbar} from "../../components/EditToolbar";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import {Container} from "@material-ui/core";

export const EditWriter = (props) => {
    return( <Container>
        <PageTitle header={pageNaming.writers.edit.header}
                   description={pageNaming.writers.edit.description}
        />
        <Edit {...props} mutationMode='pessimistic'>
            <FormWriter isNew={false} {...props} toolbar={<EditToolbar/>}/>
        </Edit>
    </Container>)
}

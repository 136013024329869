import {FormBook} from "./FormBook";
import {CreateToolbar} from "../../components/CreateToolbar";
import {Create, useRedirect} from "react-admin";
import {ROUTE_URLS} from "../../api/constants";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import * as React from "react";
import {Container} from "@material-ui/core";

export const CreateBook = (props) => {
    const redirect = useRedirect()

    return <Container>
        <PageTitle header={pageNaming.books.create.header}
                   description={pageNaming.books.create.description}
        />
        <Create {...props}
                onSuccess={() => redirect(`/${ROUTE_URLS.BOOKS}`)}
        >
            <FormBook isNew={true} toolbar={<CreateToolbar/>}/>
        </Create>
    </Container>

}

import {authUseStyles} from "./authUseStyles";
import {Box} from "@material-ui/core";
import * as React from "react";
import {useState} from "react";
import Background from "../../themes/Background";
import BackgroundImage from "../../resources/b.jpg"
import {TextInput} from "../../components/TextInput";
import {PasswordInput} from "../../components/PasswordInput";
import {useLogin, useNotify} from "react-admin";
import {AuthButton} from "../../components/authPages/AuthButton";
import {Home as HomeIcon} from "@mui/icons-material";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import {ROUTE_URLS} from "../../api/constants";

export const LoginPage = () => {
    const classes = authUseStyles()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const notify = useNotify();
    const login = useLogin();

    const handleSubmit = e => {
        setLoading(true);
        e.preventDefault();
        login({username, password}).then(() => {
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            notify(error, {type: 'warning'});
        })
    }

    return (
        <Box className={classes.container}>
            <Background
                image={BackgroundImage}
                blur={3}
                brightness={0.6}
            />
            <form className={classes.form} onSubmit={handleSubmit}>
                <Button startIcon={<HomeIcon/>}
                        component={Link}
                        to={{
                            pathname: `/${ROUTE_URLS.MAIN}`,
                        }}
                >
                    Перейти на главную страницу
                </Button>
                <TextInput label='Имя пользователя'
                           onChange={e => setUsername(e.target.value)}
                           variant='standard'
                           required
                />
                <PasswordInput label='Пароль'
                               onChange={e => setPassword(e.target.value)}
                               variant='standard'
                               required
                />
                <AuthButton type='submit'
                            loading={loading}
                >
                    Войти
                </AuthButton>
            </form>
        </Box>
    )
}
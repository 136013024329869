import {Edit} from "react-admin";
import {FormBook} from "./FormBook";
import {EditToolbar} from "../../components/EditToolbar";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import React from "react";
import {Container} from "@material-ui/core";

export const EditBook = (props) => {
    return(
        <Container>
            <PageTitle header={pageNaming.books.edit.header}
                       description={pageNaming.books.edit.description}
            />
            <Edit {...props}
                  mutationMode="pessimistic"
            >
                <FormBook {...props} isNew={false} toolbar={<EditToolbar/>}/>
            </Edit>
        </Container>
    )
}

import React from "react";
import {Layout, Sidebar} from "react-admin";
import ResearcherToolkitAppBar from "./ResearcherToolkitAppBar";
import {makeStyles} from "@material-ui/core/styles";
import authProvider from "../../auth/authProvider";

const useSidebarStyles = makeStyles(() => {
    if(authProvider.isAdmin()){
        return {};
    }
    return {
        drawerPaper: {
            //для простых пользователей (не админов) скрываем левую паннель
            width: '0px'
        }
    }
});

const ResearcherToolkitSidebar = props => {
    const classes = useSidebarStyles();
    return (
        <Sidebar {...props} classes={classes}/>
    );
};

const ResearcherToolkitLayout = props =>
    <Layout {...props}
            sidebar={ResearcherToolkitSidebar}
            appBar={ResearcherToolkitAppBar}/>;
export default ResearcherToolkitLayout;
import React from "react";
import {Editor} from "@tinymce/tinymce-react";
import PropTypes from "prop-types";
import {useInput} from "react-admin";

const TextEditor = ({source, label, ...props}) => {
    const {input: {value, onChange}} = useInput({source});
    return <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        value={value}
        onEditorChange={(newValue, editor) => {
            onChange(newValue)
        }}
        disabled={props.disabled}
        init={{
            selector: 'textarea',
            menubar: true,
            toolbar: 'undo redo | ' +
                'bold italic backcolor fontsize removeformat | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist indent outdent | wordcount',
            //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px, line-height: 4.5px; }',
            content_css: process.env.PUBLIC_URL + '/tinymce/smart-styles.css',
            language: 'ru',
            statusbar: false,
            nonbreaking_wrap: false,
            height: '100%',
            plugins: 'fullscreen preview wordcount searchreplace nonbreaking',
            encoding: 'xml',
            entities: '160,#160',
            promotion: false
        }}
    />
}

TextEditor.propTypes = {
    label: PropTypes.string,
    source: PropTypes.string.isRequired
}
export default TextEditor
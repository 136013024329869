import {Grid, Stack} from "@mui/material";
import {FunctionField, ImageField, ReferenceField, Show, SimpleShowLayout, TextField} from "react-admin";
import {Container, Typography} from "@material-ui/core";
import {ROUTE_URLS} from "../../api/constants";
import {showStyles} from "./bookStyles";
import MuiAudioPlayer from "mui-audio-player-plus";

export const ShowBook = (props) => {
    const optionRenderer = choice => <Typography
        variant='h4'>{choice.surname} {choice.name} {choice.middleName}</Typography>;
    const classes = showStyles()

    return <Container>
        <Show {...props}
              actions={false}
        >
            <SimpleShowLayout>
                <FunctionField label='' source='o' render={record => {
                    record.imageUrl = `${ROUTE_URLS.IMAGE}/${record.imageName}`
                    record.audioUrl = `${ROUTE_URLS.AUDIO}/${record.audioName}`
                }}/>
                <Grid container spacing={5}>
                    <Grid item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{textAlign: "center"}}
                    >

                        <Stack className={classes.container} spacing={3}>
                            <TextField source='name'
                                       label='Название произведения'
                                       fullWidth
                                       variant='h2'
                            />

                            <ReferenceField reference={ROUTE_URLS.WRITER} source='writerId' link={false}>
                                <FunctionField render={optionRenderer}/>
                            </ReferenceField>
                            <ImageField source='imageUrl' label="Текущее изображение" fullWidth/>
                            <FunctionField render={record => {
                                return <MuiAudioPlayer
                                    display="timeline"
                                    containerWidth={'100%'}
                                    inline
                                    src={record.audioUrl}/>
                            }}/>
                        </Stack>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    </Container>
}

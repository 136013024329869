import * as React from "react";
import {Datagrid, FunctionField, List, SearchInput, SelectField, TextField} from 'react-admin';
import {ResearchToolkitPagination} from "../../components/Pagination";
import {useTableStyles} from "../../themes/tableStyles";
import {Box} from '@mui/material';
import {makeStyles} from "@material-ui/core/styles";
import {USER_ROLE_CHOICES} from "../../api/constants";
import EmptyPage from "../../components/EmptyPage";
import {PageTitle} from "../../components/PageTitle";
import pageNaming from "../../pageNaming";
import {Container} from "@material-ui/core";

export const ListUsers = props => {
    const tableStyles = useTableStyles();

    const filters = [
        <SearchInput
            source="name"
            alwaysOn
        />
    ];

    const usersListStyles = makeStyles({
        dashboardsContainer: {
            display: "flex",
        },
        dashboardChip: {
            margin: '2px'
        }
    })();

    return <Container>
        <PageTitle header={pageNaming.users.list.header}
                   description={pageNaming.users.list.description}
        />
        <List {...props}
              title="Список пользователей"
              bulkActionButtons={false}
              exporter={false}
              pagination={<ResearchToolkitPagination/>}
              empty={<EmptyPage header='Нет пользователей'
                                description='Хотите добавить нового пользователя?'/>}
              filters={filters}
        >
            <Datagrid rowClick="edit" classes={{headerCell: tableStyles.thead}}>
                <TextField source="name" label="Имя"/>
                <TextField source="login" label="Логин"/>
                <SelectField source="role" label="Роль" choices={USER_ROLE_CHOICES}/>
                <FunctionField label="Статус"
                               render={record => <Box className={usersListStyles.dashboardsContainer}>
                                   {record?.isDisabled
                                       ? 'Заблокирована'
                                       : 'Активна'}
                               </Box>}/>
            </Datagrid>
        </List>
    </Container>;
}

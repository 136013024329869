import {required, SimpleForm, TextInput} from "react-admin";
import React from "react";

const FormClasses = ({isNew, ...props}) => {
    return <SimpleForm {...props}>
            <TextInput
                source="name"
                validate={required()}
                options={{autoComplete: 'none'}}
                label="Название класса"
            />
        </SimpleForm>
}

export default FormClasses;
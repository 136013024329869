import {createTheme} from "@material-ui/core";

export const mainTheme = createTheme({
    palette: {
        background: {
            default: '#e7e7e7',
            paper: '#f1f1f1'
        },
    },
    overrides: {
        MuiTypography: {
            body2: {
                fontSize: '18pt'
            }
        },
        RaAppBar: {
            toolbar: {
                backgroundImage: 'url("/images/19304.svg")',
                backgroundPosition: '50% 80%',
                isolation: 'isolate',
                '& button[title^="Обновить"]': {
                    display: 'none'
                },
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    zIndex: -1,
                    inset: 0,
                    background: '#810000',
                    opacity: 0.8,
                }
            }
        },
        RaLayout: {
            content: {
                backgroundImage: `url("/images/background.svg")`,
                backgroundBlendMode: "overlay",
            }
        },
        MuiContainer: {
            root: {
                overflow: 'hidden',
                backgroundColor: 'rgba(124,124,124,0.3)',
                paddingBottom: 30,
                borderRadius: 15
            },
        },
        RaImageField: {
            image: {
                maxHeight: '100%',
                maxWidth: '100%',
                margin: '0'
            }
        },
        MuiAppBar: {
            colorSecondary: {
                color: '#fff',
                backgroundColor: '#9c4040'
            },
            positionFixed: {
                transform: 'none!important',
                visibility: 'visible!important'
            }
        },

        MuiTable: {
            root: {
                tableLayout: 'fixed',
                width: '100%',
            }
        },
        MuiTableRow: {
            root: {
                height: '48px',
                '&&.expanded-row .MuiTableCell-body .MuiTypography-root': {
                    color: 'rgb(26, 41, 54)',
                    fontWeight: '600'
                },
                '&&.expanded-row .MuiTableCell-body .MuiIconButton-label': {
                    color: 'rgb(26, 41, 54)',
                    fontWeight: '600'
                },

            },
        },
        MuiTableCell: {
            body: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: '#1f0000'
            },
        },
        MuiTableHead: {
            root: {
                "& .MuiTableCell-head": {
                    backgroundColor: '#cb8a8a',
                    color: "#fff",
                    fontWeight: 'bold',
                    fontSize: '0.8rem'
                },
                "& .MuiTableCell-head:hover": {
                    backgroundColor: '#c78787',
                    color: "rgb(245, 246, 247)",
                },
                "& .MuiTableCell-head:": {
                    backgroundColor: '#c78787',
                    color: "rgb(245, 246, 247)",
                }
            }
        },
        MuiTableSortLabel: {
            root: {
                '&&:hover': {
                    color: 'rgb(245, 246, 247)',
                },
                '&&.MuiTableSortLabel-active': {
                    color: 'rgb(255,255,255)',
                }
            },
            icon: {
                fill: 'rgb(209, 216, 222)',
            }
        },
        RaDatagrid: { //Row Details styles
            expandedPanel: {
                backgroundColor: "#f5f6f7",
                color: '#929da6',
                padding: '-10px'
            },
            clickableRow: {
                "&&:hover": {
                    backgroundColor: "#c2c2c2"
                },
                transition: '200ms'
            },
            rowEven: {
                backgroundColor: '#d5d5d5'
            }
        },
        RaSidebar: {
            fixed: {
                backgroundColor: '#9c4040',
                color: '#fff'
            },
            root: {
                '&& .MuiListItem-button:hover': {
                    backgroundColor: '#833535!important',
                    color: '#fff!important',
                },
                '&& .RaMenuItemLink-icon:hover': {
                    fill: '#fff!important',
                    color: 'rgba(146,157,166,1)!important',
                },
                '&& .MuiListItem-button:active': {
                    backgroundColor: '#9c4040',
                    color: '#fff',
                    '&& svg': {
                        fill: '#fff!important',
                    }
                }
            }
        },
        RaToolbar: {
            toolbar: {
                backgroundColor: '#929da6',
                '&& .MuiButton-textPrimary:not(.Mui-disabled), .MuiButton-text:not(.Mui-disabled)': {
                    color: '#ffffff',
                    minHeight: 'inherit',
                    '&:hover': {
                        backgroundColor: 'rgb(134, 146, 155)'
                    },
                    '&:active': {
                        color: 'rgb(209, 216, 222)',
                        backgroundColor: 'rgb(134, 146, 155)',

                        '&::after': {
                            backgroundColor: 'rgb(134, 146, 155)',
                        }
                    },
                },
            }
        },
    }
});

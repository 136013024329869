import {makeStyles} from "@material-ui/core/styles";
import {filter} from "lodash";


export const useTableStyles = makeStyles({
    smallColumn: {
        width: '5.5em'
    },
    normalColumn: {
        width: '7em'
    },
    thead:{
        verticalAlign: "top"
    }
});

export const useRulesTableStyles = makeStyles({
    filterInput: {
        minWidth: '234px'
    }
});

export const useEventLogStyles = makeStyles({
    filterInput: {
        minWidth: '110px'
    }
});

const expandedRowClassName = 'expanded-row';

export const onRowExpand = (recordId) => {
    document.getElementById(`${recordId}-expand`).previousSibling.classList.add(expandedRowClassName);
}

export const onRowCollapse = () => {
    filter(document.getElementsByClassName(expandedRowClassName),
        row => !row.nextSibling?.id || '')
        .forEach(row => row.classList.remove(expandedRowClassName));
}